import React from "react";
import Layout from "../containers/layout";
import { graphql, Link, useStaticQuery } from 'gatsby'
import ContentCard from '../components/ContentCardTest/ContentCard';
import SEO from '../components/seo';

const BlogOveriew = () =>  {
  const data = useStaticQuery(graphql`
    query blogQuery {
      blogs: allSanityBlog {
          edges {
            node {
              blogIntroduction {
                title
                description
                publishedAt(formatString: "D MMMM, YYYY")
                thumbNail {
                  asset {
                    url
                  }
                }
              }
              _id
              id
              title
              slug {
                current
              }
            }
          }
      }
    }
  `)
  const itemsPerPage = 6;
  const [blogs, setBlogs] = React.useState();
  const [lastObjectPosition, setLastObjectPosition] = React.useState(itemsPerPage);

  const blogsArray = data.blogs.edges;
  const publishedBlogs = blogsArray.filter((b) => !b.node._id.startsWith('drafts.'));
  const sortedBlogs = publishedBlogs.sort((a, b) => new Date(b.node.blogIntroduction.publishedAt) - new Date(a.node.blogIntroduction.publishedAt));

  React.useEffect(() => {
    setBlogs(sortedBlogs.slice(0, itemsPerPage));
  }, []);

  const loadMoreBlogs = () => {
    const sliceBlogs = sortedBlogs.slice(lastObjectPosition, lastObjectPosition + itemsPerPage);
    setBlogs((c) => [...c, ...sliceBlogs]);
    setLastObjectPosition((c) => c + itemsPerPage);
  };

  return(
    <Layout>
      <SEO title="Blog overzicht" />
      <div className="about-us">
        <div className="wrap">
          <div className="row">
            <ContentCard horizontal size="medium" image={sortedBlogs[0].node.blogIntroduction.thumbNail.asset.url}>
              <div>
                <span>{sortedBlogs[0].node.blogIntroduction.subTitle}</span>
                <h2>{sortedBlogs[0].node.blogIntroduction.title}</h2>
                <p>{sortedBlogs[0].node.blogIntroduction.description}</p>
                <Link to={`/blog/${sortedBlogs[0].node.slug.current}`}>
                  <button className='button button--secondary'>Meer lezen</button>
                </Link>
              </div>
            </ContentCard>
          </div>
        </div>

        <section className='blogs'>
          <div className="wrap">
            <h2>Alle Blogs</h2>
            <div className="courses__overview">
            {blogs && blogs.length > 0 ? (
              blogs.map((blog) => (
                <Link to={`/blog/${blog.node.slug.current}`} className="courses__item">
                  <ContentCard size="small" image={blog.node.blogIntroduction.thumbNail.asset.url}>
                    <h3>{blog.node.title}</h3>
                    <span className="courses__date">{blog.node.blogIntroduction.publishedAt}</span>
                  </ContentCard>
                </Link>
              ))
            ): <p>Op dit moment zijn er nog geen blogposts geschreven</p>}
            {lastObjectPosition < sortedBlogs.length && (
              <div className="load-more">
                <button className="button" onClick={loadMoreBlogs}>Laad meer</button>
              </div>
            )}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default BlogOveriew
